/* Form Validation */
.error {
  font-size: 12px;
  color: #f44336;
  margin-top: 0;
  margin-left: 8px;
}

.error::before {
  display: inline;
  font-size: 16px;
  font-weight: bold;
  content: "⚠ ";
}


/* Snackbar */
.snackbar-error {
  background-color: #d9534f !important;
}

.snackbar-warning {
  background-color: #ff9800 !important;
}

.snackbar-success {
  background-color: #2b9c3e !important;
}

.snackbar-info {
  background-color: #4c7fb9 !important;
}

.snackbar-text {
  background-color: #adadad !important;
}


/* contentWindow and headline */

.contentWindow {
  width: calc(100vw - 35px);
}

@media (min-width: 960px) {
  .contentWindow {
    width: calc(100vw - 245px);
  }
}

.headlineBox {
  display: flex;
  margin: 15px 0 8px 0;
  padding: 0 10px 0 0;
  background-color: #e7e7e7;
  font-size: 0.9em;
  align-items: center;
  justify-content: flex-start;
  /*border-bottom: 1px solid #1b6a88;*/
}

.subHeadlineBox {
  display: flex;
  margin-bottom: 9px;
}

.headlineIconBox {
  display: flex;
  padding: 9px 10px 9px 10px;
  margin-right: 10px;
  /*background-color: #bcc7d0;*/
}

.headlineIcon {
  color: #1b6a88;
}

.headlineIconText {
  margin-left: 8px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #1b6a88;
}


/* Material Table */
.MuiTableSortLabel-root.MuiTableSortLabel-root, .MuiTableCell-head {
  color: #1b6a88 !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-active{
  color: #2287ad !important;
}
.MuiTableSortLabel-root.MuiTableSortLabel-root:hover{
  color: #2287ad !important;
}


/* Image Caption */
figure {
  position: relative;
  display: inline-block;
  margin: 0;
  padding: 0;
}

figcaption {
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.60);
  padding: 5px;
}

.figMarker {
  position: absolute;
  left: 10px;
  bottom: 10px;
  padding: 5px 8px;
  color: #fff;
  background-color: #096382;
  border: 1px solid #fff;
  border-radius: 8px;
}

figure:hover img {
  opacity: .9;
}

figure:hover figcaption {
  display: block;
}

.figIcon:hover {
  color: #007a91;
  cursor: pointer;
}

.figIconActive {
  color: #118fa4;
  cursor: pointer;
}

.dateOutOfRange {
  color: #d9534f;
}

/* fade out text box for overflowing text in descriptions */
.fadeOutText {
  -webkit-mask-image: linear-gradient(180deg, #000 80%, transparent);
}


/* vis timeline classes */
.vis-item.active {
  color: #000000;
  background-color: #caeac9;
  border-color: #79b2a6;
}
.vis-item.paused {
  color: #FFFFFF;
  background-color: #bea182;
  border-color: #818181;
}
.vis-item.surcharge,
.buttonSurcharge {
  color: #ffffff;
  background-color: #d59e14;
  border-color: #c07615;
}
.vis-item.blackOut,
.buttonBlackOut {
  color: #ffffff;
  background-color: #534262;
  border-color: #80749a;
}
.vis-item.horus_fullyBooked,
.buttonHorusFullyBooked {
  color: #ffffff;
  background-color: #d55e14;
  border-color: #9a310a;
}
.vis-item.hotel_fullyBooked,
.buttonHotelFullyBooked {
  color: #ffffff;
  background-color: #d0075b;
  border-color: #b0064d;
}
.vis-item.available,
.buttonAvailable {
  color: #176933;
  background-color: #daf1d5;
  border-color: #93b995;
}
.vis-item.vis-selected.available,
.vis-item.vis-selected.surcharge,
.vis-item.vis-selected.blackOut,
.vis-item.vis-selected.horus_fullyBooked,
.vis-item.vis-selected.hotel_fullyBooked,
.buttonSelected {
  color: #ffffff;
  background-color: #70cbea;
  border-color: #458aa1;
}

.buttonDelete {
  color: #ffffff;
  background-color: #706069;
  border-color: #362f33;
}

.timelineLabel {
  padding: 3px 5px;
  border-radius: 5px;
}

/* sub headline */
.grayH2 {
  text-align: center;
  color: #a4a4a4;
  margin-bottom: 5px;
  font-weight: 900;
}


/* clean css table */
.cleanTable table {
  border-collapse: collapse
}
.cleanTable th, .cleanTable td {
  border: 1px solid #b7b7b7;
  padding: 5px;
}
.cleanTable a {
  color: #185fb4;
}
.cleanTable a:hover {
  color: #468fec;
}



/* Table Action Button (Link) */
.tableActionButton {
  display:flex;
  align-items:center;
  justify-content: center;
  text-align: center;
  width: 120px;
  min-height: 28px;
  font-size: 12px;
  line-height: 14px;
  text-decoration: none;
  padding: 3px 4px 3px 4px;
  cursor: pointer;
  background-color: #25A9D5;
  color: #ffffff !important;
  border: 0;
  border-radius: 8px;
  font-weight: 600;
}

.tableActionButton:hover {
  color: #ffffff;
  background-color: #0a7498;
}

/* Form Action Button (real button) */
.formActionButton {
  width: 150px;
  min-height: 38px;
  cursor: pointer;
  background-color: #25A9D5;
  color: #FFFFFF;
  border: 0;
  border-radius: 8px;
  font-weight: 600;
}

.formActionButton:hover {
  background-color: #0a7498;
}

/* multiple input fields in form grid */
.inputGrid {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  gap: 8px;
  width: 95%;
  margin-bottom: 10px;
}


/* general hyperlink colors */
a {
  color: #185fb4;
}
a:hover {
  color: #468fec;
}


/* subscription details */
.sellCycleIcon {
  border-radius: 5px;
  padding: 4px 7px;
  color: #704f4f;
  background-color: #f0f0f4;
  border-top: 3px solid #A10707;
}

.currencyCaption {
  font-size: 10px;
  color: #555;
  text-align: right;
}

.ahrefButton {
  color: #185fb4;
  border: 1px solid #185fb4;
  background-color: #e2e8ef;
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
  box-shadow: 5px 5px 5px 1px #eaeaea;
}
.ahrefButton:hover {
  color: #468fec;
  border: 1px solid #468fec;
  background-color: #ecf0f4;
  border-radius: 5px;
  padding: 5px 15px;
  text-decoration: none;
}